/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import EngineeringIcon from '@mui/icons-material/Engineering';
import BadgeIcon from '@mui/icons-material/Badge';
import StorageIcon from '@mui/icons-material/Storage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import CableIcon from '@mui/icons-material/Cable';
import WorkIcon from '@mui/icons-material/Work';
import GradingIcon from '@mui/icons-material/Grading';
import SettingsIcon from '@mui/icons-material/Settings';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import AddCardIcon from '@mui/icons-material/AddCard';
import MapIcon from '@mui/icons-material/Map';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PaidIcon from '@mui/icons-material/Paid';
import CategoryIcon from '@mui/icons-material/Category';
import ApprovalIcon from '@mui/icons-material/Approval';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import PaymentsIcon from '@mui/icons-material/Payments';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MoneyIcon from '@mui/icons-material/Money';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { RoleList } from 'src/common/interface/roleList';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DevicesIcon from '@mui/icons-material/Devices';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import ListIcon from '@mui/icons-material/List';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PortraitIcon from '@mui/icons-material/Portrait';
import DvrIcon from '@mui/icons-material/Dvr';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BallotIcon from '@mui/icons-material/Ballot';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

export interface MenuItem {
  link?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  badge?: string;
  items?: MenuItem[];
  name: string;
  key?: string;
  type?: string;
}

export interface MenuItems {
  items: MenuItem[];
}

const menuItems: MenuItems[] = [
  {
    items: [
      {
        name: 'Dashboard',
        link: '/dashboard',
        icon: DashboardIcon,
        key: 'Dashboard'
      },
      {
        name: 'My Requests',
        link: '',
        icon: BadgeIcon,
        key: 'Requests Management',
        items: [
          {
            name: 'Leave Request',
            link: '/request/leave',
            icon: BallotIcon,
            key: 'Leave Request - List'
          },
          {
            name: 'Profile Edit Request',
            link: '/request/edit/profile',
            icon: AccountBoxIcon,
            key: 'Profile Edit Request - Create'
          },
          {
            name: 'Asset Request',
            link: '/request/asset',
            icon: LaptopMacIcon,
            key: 'Asset Request - Create'
          },
          {
            name: 'Provident Fund Request',
            link: '/request/pf',
            icon: BallotIcon,
            key: 'PF Update Request - Create'
          }
        ]
      },
      {
        name: 'Request Approval',
        link: '',
        icon: BadgeIcon,
        key: 'Request Approval',
        items: [
          {
            name: 'Leave Approval',
            link: '/approval/leave',
            icon: BallotIcon,
            key: 'Leave Approval - List'
          }
        ]
      },
      {
        name: 'Holidays',
        link: 'holidays/list',
        icon: CalendarMonthIcon,
        key: 'Holiday List',
        type: 'public'
      }
    ]
  }
];

export default menuItems;
