export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}
export enum SortColumn {
  Id = 'id',
  CreatedAt = 'createdAt'
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Others = 'others'
}

export enum EmployeeType {
  Interns = 'interns',
  Consultants = 'consultants',
  Contract = 'contract',
  FullTimeEmployee = 'fullTimeEmployee'
}
export enum MaritalStatus {
  Single = 'single',
  Married = 'married'
}
export enum JobStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Onleave = 'Onleave',
  Terminated = 'Terminated'
}

export enum BloodGroup {
  APositive = 'A+',
  ANegative = 'A-',
  BPositive = 'B+',
  BNegative = 'B-',
  ABPositive = 'AB+',
  ABNegative = 'AB-',
  OPositive = 'O+',
  ONegative = 'O-'
}

export enum ProfileStatus {
  Pending = 'pending',
  Locked = 'locked',
  Unlocked = 'unlocked',
  LockRequested = 'lock-requested',
  UnlockRequested = 'unlock-requested',
  ChangeRequested = 'change-requested'
}

export enum ErrorCodes {
  InternalError = 500,
  success = 200,
  UnAuthorized = 401
}

export enum ErrorTypes {
  Unhandled = 'unhandled',
  Validation = 'validation'
}

export enum UserTypes {
  Developer = 'Developer',
  Administrator = 'Administrator',
  Employee = 'Employee'
}
