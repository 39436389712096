import React from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
//import { StylesProvider } from '@mui/styles';
type ThemeProps = {
  children?: React.ReactNode;
};

const ThemeProviderWrapper: React.FC<ThemeProps> = (props) => {
  //const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  //const [themeName, _setThemeName] = useState(curThemeName);
  const currentTheme = 'PureLightTheme';
  const theme = themeCreator(currentTheme);
  //const theme = themeCreator(themeName);
  // const setThemeName = (themeName: string): void => {
  //   localStorage.setItem('appTheme', themeName);
  //   _setThemeName(themeName);
  return <ThemeProvider theme={theme}> {props.children}</ThemeProvider>;
};

//   return (
//     <StylesProvider injectFirst>
//       <ThemeContext.Provider value={setThemeName}>
//         <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
//       </ThemeContext.Provider>
//     </StylesProvider>
//   );
// };

export default ThemeProviderWrapper;
