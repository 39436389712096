import { Navigate, RouteObject } from 'react-router';
import { lazy, Suspense } from 'react';
import SuspenseLoader from '../components/SuspenseLoader';
import SidebarLayout from 'src/layouts/SidebarLayout';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Dashboard = Loader(
  lazy(() => import('src/pages/UserManagement/Dashboard'))
);

const Asset = Loader(
  lazy(() => import('src/pages/RequestManagement/AssetRequest'))
);

const Leave = Loader(
  lazy(() => import('src/pages/RequestManagement/LeaveRequest'))
);

const ProvidentFund = Loader(
  lazy(() => import('src/pages/RequestManagement/ProvidentFundRequest'))
);
const EditProfileRequest = Loader(
  lazy(() => import('src/pages/RequestManagement/EditProfileRequest'))
);
const LoggedInResetPassword = Loader(
  lazy(() => import('src/pages/UserManagement/LoggedInResetPassword/index'))
);

const LeaveApproval = Loader(
  lazy(() => import('src/pages/RequestApproval/LeaveApproval'))
);
const HolidayList = Loader(lazy(() => import('src/pages/HolidaysManagement')));

const MyProfile = Loader(
  lazy(() => import('src/pages/UserManagement/Profile'))
);

const userSiteRoutes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />
      }
    ]
  },
  {
    element: <SidebarLayout />,
    children: [
      {
        path: 'logged-in-reset-password',
        element: <LoggedInResetPassword />
      },
      {
        path: 'my-profile',
        element: <MyProfile />
      },
      {
        path: '/',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: '/dashboard',
        element: <Dashboard />
      },
      {
        path: '/request/asset',
        element: <Asset />
      },
      {
        path: '/request/leave',
        element: <Leave />
      },
      {
        path: '/request/pf',
        element: <ProvidentFund />
      },
      {
        path: '/request/edit/profile',
        element: <EditProfileRequest />
      },
      {
        path: '/approval/leave',
        element: <LeaveApproval />
      },
      {
        path: '/holidays/list',
        element: <HolidayList />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
];

export default userSiteRoutes;
