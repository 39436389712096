import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ErrorCodes, ErrorTypes } from 'src/common/enums/enums';
import { ErrorResponse } from 'src/common/interface/ErrorResponse';

export const extractErrorCodeFromError = (error: AxiosError): Number => {
  return error?.response?.status || ErrorCodes.InternalError;
};

export const formatAxiosError = (error: any): ErrorResponse => {
  const response: ErrorResponse = {
    statusCode: error.statusCode || ErrorCodes.InternalError,
    data: error?.errors || {},
    message: error?.message,
    type: error.isValidationError
      ? ErrorTypes.Validation
      : ErrorTypes.Unhandled || ErrorTypes.Unhandled
  };
  return response;
};

export const handleError = (formik: any, error: ErrorResponse) => {
  if (error.type === ErrorTypes.Unhandled) {
    toast.error(error.message);
  } else if (error.type === ErrorTypes.Validation) {
    Object.keys(error?.data).forEach((key) => {
      formik.errors[key] = error?.data[key];
    });
  }
};
