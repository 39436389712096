import { useRoutes } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import baseSiteRoute from './routes/SiteRoutes';
import userSiteRoutes from './routes/UserRoutes';
import moment from 'moment';
import request from './utils/request';
import CardDataLoader from './components/DataLoader/CardDataLoader';
import 'src/assets/css/style.css';
import { UserTypes } from './common/enums/enums';

const App = () => {
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const [loading, setLoading] = useState<boolean>(false);

  let loggedUser = JSON.parse(localStorage.getItem('userData'));

  // Login with token for SSO
  useEffect(() => {
    if (token) {
      loginWithToken(token);
    }
  }, [token]);

  const loginWithToken = async (token) => {
    setLoading(true);
    try {
      const loginData = await request.get(`auth/login`, {
        headers: {
          Authorization: token ? 'Bearer ' + token : null
        }
      });
      localStorage.setItem('userData', JSON.stringify(loginData.data));
      const expiry = loginData?.data?.expiry - 20;
      const refreshAt = moment().add({ seconds: expiry });
      localStorage.setItem('expiry', JSON.stringify({ refreshAt }));
      if (
        loginData.data.type === UserTypes.Employee ||
        loginData.data.type === UserTypes.Administrator
      ) {
        loggedUser = JSON.parse(localStorage.getItem('userData'));
        navigate('/');
      } else {
        await request.post('auth/logout');
        localStorage.clear();
        window.location.href = `${process.env.REACT_APP_ADMINUI_URL}`;
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_ADMINUI_URL}`;
      setLoading(false);
    }
  };

  // Refresh Token
  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, 30000);
    ref.current = interval;
    return () => clearInterval(interval);
  }, []);

  const refreshToken = async () => {
    const tokenExpiry = JSON.parse(localStorage.getItem('expiry'));
    if (
      tokenExpiry?.refreshAt &&
      moment(tokenExpiry?.refreshAt).diff(moment(), 'second') < 15
    ) {
      try {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const response = await request.get(`auth/refresh`, {
          headers: {
            Authorization: userData ? 'Bearer ' + userData?.refreshToken : null
          }
        });
        localStorage.setItem('userData', JSON.stringify(response.data));
        const expiry = response?.data?.expiry - 20;
        const refreshAt = moment().add({ seconds: expiry });
        localStorage.setItem('expiry', JSON.stringify({ refreshAt }));
      } catch (err) {
        console.log(err);
      }
    }
  };
  // End: Refresh Token
  if (loading) return <CardDataLoader />;
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {loggedUser?.type === UserTypes.Employee ||
        loggedUser?.type === UserTypes.Administrator
          ? useRoutes(userSiteRoutes)
          : useRoutes(baseSiteRoute)}
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
