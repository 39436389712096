import BaseLayout from '../layouts/BaseLayout';
import { Navigate, RouteObject } from 'react-router';
import { lazy, Suspense } from 'react';
import SuspenseLoader from '../components/SuspenseLoader';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const ErrorLoginWithToken = Loader(
  lazy(() => import('../pages/UserManagement/ErrorLoginWithToken'))
);

const baseSiteRoute: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <ErrorLoginWithToken />
      },
      {
        path: '*',
        element: <Navigate to="/" replace />
      }
    ]
  }
];
export default baseSiteRoute;
