import loader from './loader.gif';
import { Box } from '@mui/material';

const CardDataLoader = () => {
  return (
    <Box
      alignContent={'center'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '150vh',
        alignItems: 'center'
      }}
    >
      <img
        alt="loader"
        src={loader}
        className="loader"
        width={50}
        height={50}
      />
    </Box>
  );
};
export default CardDataLoader;
